export interface SERVICE_COUNTRIES_STATE_CENTER_PHONE {
  name: string;
  phonenumber: string[];
}
export interface SERVICE_COUNTRIES_STATE_CENTER {
  name: string;
  address: string;
  email: string[];
  phone: SERVICE_COUNTRIES_STATE_CENTER_PHONE[];
}
export interface SERVICE_COUNTRIES_STATE {
  name: string;
  serviceCenters: SERVICE_COUNTRIES_STATE_CENTER[];
}
export interface SERVICE_COUNTRIES {
  country: string;
  aliases: string[];
  headline: SERVICE_COUNTRIES_STATE_CENTER;
  states: SERVICE_COUNTRIES_STATE[];
}

export const locations: SERVICE_COUNTRIES[] = [
  {
    country: 'Nigeria',
    aliases: ['ng', 'nigeria'],
    headline: {
      name: 'Head Office',
      address:
        'Location: GIG Logistics Digital Hub. No 1 Sunday Ogunyade Street, Gbagada Expressway, Beside Eterna Fuel Station, Gbagada Lagos, Nigeria.',
      email: ['info@giglogistics.com'],
      phone: [
        {
          name: 'Main line:',
          phonenumber: ['(+234)813 985 1120'],
        },
      ],
    },
    states: [
      {
        name: 'Lagos',
        serviceCenters: [
          {
            name: 'Alaba International',
            address:
              'Cs1 Ground Floor Corner Stone Plaza By Dobbil Avenue Along Phone Village Road, Electronics Section Alaba International Market.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Ajah 1',
            address: 'KM 25, Lekki-Epe Express way, Ajiwe-Ajah.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Ajah 2',
            address:
              'KM 22, Lekki-Epe Express way, Opp. Jeffrey’s Plaza, by Abraham Adesanya Roundabout, Ajah.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Addo Badore',
            address:
              'Tripple Ace Dew Building, opposite Enyo filling Station Addo road.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Akowonjo',
            address: '41 Shasha Road, Akowonjo Junction, Dopemu, Lagos.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Awoyaya',
            address:
              'Km 36, Lekki-Epe Express Way, by Ogunfayo Bus Stop, Eputu, Awoyaya Lagos.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Amuwo-Odofin',
            address:
              'Shop A105 Cosjane Mall Opposite Diamond Estate, By Festac Link Bridge, Amuwo Odofin',
            email: ['info@giglogistics.com'],
            phone: [],
          },

          {
            name: 'Cele Okota',
            address: '103, Okota Road, Cele.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Festac',
            address:
              '1st Avenue Road,Festac first gate, beside Inec office, Festac town, Lagos.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Gbagada',
            address: '7, Hospital Rd, Ifako, Gbagada, Lagos.',
            email: ['info@giglogistics.com'],
            phone: [],
          },

          {
            name: 'Ikeja 1',
            address:
              '9, Medical Road, former Simbiat Abiola Way, Opp, Zenith Bank.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Ikeja 2',
            address: '80, Awolowo Way, Ikeja, Lagos.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Gbagada Express Center',
            address:
              'GIG Logistics Digital Hub. No 1 Sunday Ogunyade Street, Gbagada Expressway,Beside Eterna Fuel Station, Gbagada Lagos',
            email: ['info@giglogistics.com'],
            phone: [{ name: '', phonenumber: ['(+234)813 985 1120'] }],
          },
          {
            name: 'Ikoyi',
            address: '103 Awolowo road, Ikoyi Lagos.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Ikosi',
            address: '16 Ikosi Road, Ketu Lagos.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Ikorodu',
            address: 'Sabo Road Garage, Ikorodu.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Fadeyi',
            address: '69, Ikorodu Road, Fadeyi, Lagos.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Ikotun',
            address: '29,Idimu Road, Opp. Local Govt, Council, Ikotun, Lagos.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Ilupeju',
            address:
              'Flat 1, Block 1, LSDPC Estate Beside UBA, 12, Industrial Avenue, Cappa Bus-stop, Ilupeju, Lagos.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'International trade fair',
            address:
              'Shop D77 & D78, Abia Plaza, BBA, Lagos Int’ Trade Fair Complex, Lagos.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Ipaja',
            address:
              '164, Lagos Abeokuta Express Way, beside Diamond Bank, Lagos.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Jibowu',
            address: '20 Ikorodu Express Road, Jibowu, Lagos.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Lekki Admiralty',
            address:
              'No 1A, Wole Ariyo Street, Beside First Bank, Lekki Phase 1.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Lekki Admiralty 2',
            address: 'Jubilee Mall Admiralty Way, Lekki Phase One, Lekki.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Lekki (Fola Osibo)',
            address:
              'Ground floor Legends Place Mall Plot 29 Fola Osibo Lekki Phase 1, Lagos.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Oniru Lekki',
            address:
              'Banex Mall Suite V.GL 01, Akiogun Road, Oniru, Lekki Lagos.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Igbo Efon',
            address: 'Km 17 Scapular plaza Igbo efon.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Old Ojo Road',
            address: 'Old Ojo Road, by Police Station Lagos.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Ogba',
            address: '3 Ijaiye Road, Beside FCMB Ogba.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Ogudu',
            address: '141, Ogudu road Beside UBA, Studio24 building, Ogudu.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Opebi',
            address: '62 Opebi Road, Ikeja Lagos.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Osapa (Canal Mall)',
            address:
              '2 Ganiu Eletu Way, Osapa London, Lekki-Epe Expressway, Lagos.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Oyingbo',
            address:
              'No 25 Otto Causeway Opp Iddo bus top, Iddo Ebute Metta Lagos.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Sango',
            address:
              '3, Abeokuta – Lagos Expressway, Sango Ota, Opp. Sango Bridge.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Surulere',
            address: '26, Adeniran Ogunsanya, Surulere, Lagos.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Volks',
            address: '169, Badagry Expressway, Volkswagen Bus Stop.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Victoria Island',
            address: '1436 Sanusi Fafunwa Street, Victoria Island, Lagos.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Yaba',
            address:
              'Tejuosho Ultra Modern Market, Ojuelegba Road, Yaba, Lagos.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Victoria Island 2',
            address:
              '272b Akin Adeshola Street, Beside Honda Place, Victoria Island, Lagos.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Epe',
            address:
              'Animashaun Plaza, Beside Petrocam fuel station, Near Epe T-junction, Epe.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Isolo',
            address: '43, Osolo Way, Ajao Estate, Ekwu Awolo House.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Ojodu Berger',
            address: '47A Ogunnusi Road, Ojodu berger.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
        ],
      },
      {
        name: 'Abuja',
        serviceCenters: [
          // {
          //   name: 'Garki',
          //   address:
          //     'Shop C11, Efab Plaza, Beside Ibrahim Coomasie Cantonment, Area 11, Garki.',
          //   email: ['info@giglogistics.com'],
          //   phone: [],
          // },
          {
            name: 'Garki',
            address:
              'SICCONS PLAZA, Opposite Unity House, Beside Lifemate Furniture, Garki Area 11',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Gwarimpa',
            address:
              'House 38, 3rd Avenue Gwarimpa, Opposite Union Bank Abuja.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Gwarimpa 2',
            address:
              'Suite A1 Bricks and More Plaza, 6th Avenue by 7th Avenue Junction Gwarinpa',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Gwagwalada',
            address:
              'Secretariat road beside WAEC, opposite Aso-Oke Hotel, Gwagwalada.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Kubwa 1',
            address:
              'Opposite 2/2 court junction, block 43, Gado Nasko way, along El-rufai Bustop, Kubwa.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Kubwa 2',
            address:
              'Opposite Ignobis hotel plot 17 Gidin dutse layout, kubwa.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Kubwa 3',
            address: 'Kukwaba General Park, Kubwa.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Maraba Experience Center',
            address:
              'No 132 Giza Plaza Opp Chrisgold Plaza Beside MTN Office Mararaba',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Madalla',
            address: 'Madalla by Mobil, along Abuja - Kaduna Express Road.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Utako 1',
            address:
              'Plot 113 I.V.W. Osisiogu Street, beside Utako Police Station Utako Abuja.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Utako 2',
            address:
              'Abraham Plaza, Suite A13 Plot, 6 A.E. Ekukinam St, Utako  Abuja',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Wuse 2',
            address:
              '80 Aminu Kano crescents opposite Sherif plaza beside Wema Bank Banex wuse 2',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Wuse 2',
            address:
              '12 Nurnberger Platz, by Lobito junction, Ademola Adetokunbo Crescent, before Transcorp Hilton, Wuse 2',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Zuba',
            address: '206, Zuba Market, Opp. Lagos Line, Zuba',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'AREA 1',
            address: 'SH 034, Area 1 shopping Plaza, Area 1 Abuja',
            email: ['info@giglogistics.com'],
            phone: [],
          },
        ],
      },

      {
        name: 'Abia',
        serviceCenters: [
          {
            name: 'Aba 1',
            address: 'No 5 Asa Road Former/Old Nitel Building Aba',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Aba 2',
            address: 'G.R.A 30 Brass Street after Jevinic Restaurant Aba',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Umahia-1',
            address:
              '8 Mission Hill, Opposite Villaroy Hotel, Umuahia Main Town',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Umahia-2',
            address:
              'No 60 Aba Road, Close to MTN Office at Aba Road, Umuahia.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
        ],
      },
      {
        name: 'Ebonyi',
        serviceCenters: [
          {
            name: 'Abakaliki',
            address: 'Central Park, opposite International Market, Abakaliki',
            email: ['info@giglogistics.com'],
            phone: [],
          },
        ],
      },
      {
        name: 'Ogun',
        serviceCenters: [
          {
            name: 'Abeokuta',
            address: '62, Tinubu Street, Ita Eko, Abeokuta, Ogun State',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Abeokuta FUNAAB',
            address:
              'Alumni Building , Federal University of Agric (FUNAAB) Abeokuta, Ogun State',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Sango Otta',
            address:
              '3, Abeokuta – Lagos Expressway, Sango Ota, Opp. Sango Bridge',
            email: ['info@giglogistics.com'],
            phone: [],
          },
        ],
      },
      {
        name: 'Ekiti',
        serviceCenters: [
          {
            name: 'Ado Ekiti Center',
            address:
              'Soladola petrol station, beside Apc secretariat, opposite moferere junction, along ikere road, Ajilosun',
            email: ['info@giglogistics.com'],
            phone: [],
          },
        ],
      },
      {
        name: 'Ondo',
        serviceCenters: [
          {
            name: 'Akure Center',
            address: '22 Oyemekun Road, Opposite SLOT, Akure, Ondo State.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Ondo Town',
            address:
              '30, Yaba Street, Opposite Crunchies, Ondo Town, Ondo State.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
        ],
      },
      {
        name: 'Akwa Ibom',
        serviceCenters: [
          {
            name: 'Uyo 1',
            address:
              '3, Monsignor Akpan Avenue, Itam industrial layout, opp Timber Market, Itam.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Uyo 2',
            address: 'No 108 Oron Road, beside First bank,Uyo.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
        ],
      },
      {
        name: 'Anambra',
        serviceCenters: [
          {
            name: 'Awka',
            address:
              'Elite Shopping Complex Opp Crunchies fries, Enugu/Onitsha Expressway, Awka',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Onitsha',
            address:
              '2 Awka Road, By DMGS Junction, Beside All Saints Anglican Cathedral, Onitsha',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Nnewi',
            address:
              'No 73 – Owerri Road, Martina Chukwuma Plaza (Innoson Plaza), Opposite The Salvation Army Church, Umudim Nnewi',
            email: ['info@giglogistics.com'],
            phone: [],
          },
        ],
      },
      {
        name: 'Bayelsa',
        serviceCenters: [
          {
            name: 'Yenagoa 1',
            address:
              'Kpansia Epia, Opposite Wema Bank by INEC Junction, Yenogoa',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Yenagoa 2',
            address:
              'Pam House, Tamic Road and School Road, Okutukutu By Isaac Boro Expressway, Yenogoa',
            email: ['info@giglogistics.com'],
            phone: [],
          },
        ],
      },
      {
        name: 'Bauchi',
        serviceCenters: [
          {
            name: 'Bauchi',
            address:
              'Shop 7, Yandoka Road, Adjacent MTN office. Opposite First Baptist Church, Bauchi State',
            email: ['info@giglogistics.com'],
            phone: [],
          },
        ],
      },
      {
        name: 'Crossriver',
        serviceCenters: [
          {
            name: 'Calabar',
            address: '29 Ndidem Usang Iso Road ( Aka Marian Road) Calabar.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
        ],
      },
      {
        name: 'Delta',
        serviceCenters: [
          {
            name: 'Asaba 1',
            address: 'Asaba Onitsha Express way, By Head Bridge.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Asaba 2',
            address: '445, Nnebisi Road, opposite Zenith Bank, Asaba.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Asaba 3',
            address: 'Suit 53/54 Independence Mall Okpanam Rd, Asaba.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Warri 1',
            address: '138, Effurun-Sapele Rd, by Airport Junction Warri.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Warri 2',
            address:
              '116, Effurun-Sapele Warri Road, Effurun Opp. Our Ladies High School.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Warri-Effurun Center',
            address:
              'Shop 5, Eku Plaza, 128 Effurun-Sapele road, Opp Solidas. Adjacent PZ Cussons by 7up Bus stop.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: ' Ughelli Center',
            address:
              'No 6B, Uduere/Agbaha Road, Off Ughelli-Warri Express Way, Ughelli.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
        ],
      },
      {
        name: 'Edo',
        serviceCenters: [
          {
            name: 'Uselu',
            address: '202, Uselu Lagos Road, Ugbowo Benin City.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Akpakpava',
            address: '112, Akpakpava Road, Benin City.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Airport Road',
            address: 'Shop 1, Omegatron Plaza, 47 Airport Road, Benin City.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Ramat Park',
            address:
              '42, Benin/Agbor Road, Oregbeni, Ramat Park Benin City, Edo State.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Auchi',
            address: 'Okene Express Way, Opp Auchi Polytechnic, Auchi.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Ekpoma',
            address:
              'Along Benin -Auchi expressway, Beside Big Joe park, Ekpoma.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Sapele Road',
            address:
              '58, Sapele Road, Beside Genesis Restaurant, opposite Uwa Junction, Benin City',
            email: ['info@giglogistics.com'],
            phone: [],
          },
        ],
      },
      {
        name: 'Enugu',
        serviceCenters: [
          {
            name: 'Enugu 1',
            address:
              'No 1, P & T Quarters, Market Road, Opp Osisatech Polytechnic, Enugu.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Enugu 2',
            address: '67, Zik Avenue Uwani Enugu.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Nsukka',
            address: 'No 64 Owerrani, Enugu Road, Nsukka.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
        ],
      },
      {
        name: 'Kwara',
        serviceCenters: [
          {
            name: 'Ilorin 1',
            address:
              '151, Ibrahim Taiwo Road (Upper Taiwo), Adjacent Chicken Republic, Ilorin',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Ilorin 2',
            address:
              '34B, University of Ilorin Road, Beside Reo Cakes Plaza, Tanke, Ilorin.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
        ],
      },
      {
        name: 'Oyo',
        serviceCenters: [
          {
            name: 'Ibadan 1',
            address: 'Town Planning Complex, by Sumal Foods, Ring Road, Ibadan',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Ibadan 2',
            address:
              'Suite 5, Kamal memorial plaze, former iyalode complex, opposite funcktionals clothing, bodija - UI road, UI Ibadan.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Ibadan 3',
            address:
              'Bovas Filling Station, 106/107 Agbaakin Layout Iwo Road Ibadan.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Ogbomoso',
            address:
              'Eterna Fuel Station (Akala Complex), Opp Zenith Bank Starlight Ogbomoso',
            email: ['info@giglogistics.com'],
            phone: [],
          },
        ],
      },
      {
        name: 'Taraba',
        serviceCenters: [
          {
            name: 'Jalingo',
            address:
              '106 White Castle Plaza Barde Way Beside A.U.K Kirbir Shopping Plaza, Jalingo.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
        ],
      },
      {
        name: 'Jigawa',
        serviceCenters: [
          {
            name: 'Dutse',
            address:
              'Government House Round-About, Asamau House Block B, Number 8, by Airtel Office, Dutse, Jigawa State.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
        ],
      },
      {
        name: 'Gombe',
        serviceCenters: [
          {
            name: 'Gombe',
            address:
              'Shop 4, EL-ZAKS Plaza Opposite Conoil Filling Station, Ashaka Road, Gombe',
            email: ['info@giglogistics.com'],
            phone: [],
          },
        ],
      },
      {
        name: 'Borno',
        serviceCenters: [
          {
            name: 'Maiduguri',
            address:
              '10A, Golden plaza, opposite Elkanemi College of Islamic Theology, Jos Road, Maiduguri',
            email: ['info@giglogistics.com'],
            phone: [],
          },
        ],
      },
      {
        name: 'Plateau',
        serviceCenters: [
          {
            name: 'Jos 1',
            address: 'Plaza 1080, Yakubu Gowon way, Dadin kowa second gate.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Jos 2',
            address:
              'Angwan Soya Zaria Road bypass, Opposite Jankwano Bingham University Teaching Hospital.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
        ],
      },
      {
        name: 'Kaduna',
        serviceCenters: [
          {
            name: 'Kaduna 1',
            address:
              'Jos Road Plaza, No 19/20, Ahmadu Bello Way, by  Jos Road Opposite Fidelity Bank.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Kaduna 2',
            address: "Shop A04, No 6 Gidanbu Plaza, Kaduna to Lagos Road, Opposite Kaduna Old Airport Road, Kaduna.",
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Kaduna 3',
            address:
              'Nnamdi Azikiwe Expressway by Command Junction, close to Samrada Fuel Station (beside 911 bakery).',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Zaria',
            address:
              'Shop 2, D2 Plaza No. 18 Sokoto road beside Shagalinku London Hotel after MTD Junction, Sabon Gari Zaria.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
        ],
      },
      {
        name: 'Kano',
        serviceCenters: [
          {
            name: 'Kano 1',
            address:
              'No 1 bompai road by Tarawa Balewa way, Opp Grand Central Hotel Kano.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Kano 2',
            address: 'Centro Plaza, Opposite Buk Old Site, Kabuga, Kano.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Kano 3',
            address:
              'Khadijah house, Zaria Road opposite Kano State House of Assembly',
            email: ['info@giglogistics.com'],
            phone: [],
          },
        ],
      },
      {
        name: 'Katsina',
        serviceCenters: [
          {
            name: 'Katsina 1',
            address:
              'Shema Plaza Ground Floor, Mani Road, by W.T.C Round-About, Katsina State.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Katsina 2',
            address:
              'Abudullahi Sarki Muktar Road, Along Kiddies Round-About, Near Tukur Jikamshi Residence Katsina.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
        ],
      },
      {
        name: 'Kebbi',
        serviceCenters: [
          {
            name: 'Birnin Kebbi',
            address:
              'Ahmadu Bello Way opp alhaji boye coca cola Depot birnin kebbi, kebbi state.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
        ],
      },
      {
        name: 'Nasarawa',
        serviceCenters: [
          {
            name: 'Lafia',
            address:
              'Shops 1 & 2 Police Officers Mess, Opposite Polaris Bank, Jos Road, Lafia.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
        ],
      },
      {
        name: 'Kogi',
        serviceCenters: [
          {
            name: 'Lokoja',
            address:
              'No 1 IBB Way, Adankolo, Lokoja, close to Federal Medical Center.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
        ],
      },
      {
        name: 'Benue',
        serviceCenters: [
          {
            name: 'Makurdi',
            address:
              'No 4 Old Otukpo Rd, Opposite Dester’s by Savannah Roundabout.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
        ],
      },
      {
        name: 'Niger',
        serviceCenters: [
          {
            name: 'Minna',
            address:
              'Landmark: After Mr Biggs beside Nepa Office, Farm Center Area, Tunga, Minna – Niger State.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
        ],
      },
      {
        name: 'Imo',
        serviceCenters: [
          {
            name: 'Owerri 1',
            address:
              'Plot C31, Relief Road, by Relief Junction, Off Egbu Road, Owerri.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Owerri 2',
            address: 'Odonko Plaza, No 7 Nwaturuocha street, Ikenegbu Owerri.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Owerri 3',
            address:
              'Shop 9 Lion Yard Plaza, plot 26A/26B Federal Housing Estate along Umuguma Road (World Bank), New Owerri, Imo State.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
        ],
      },
      {
        name: 'Osun',
        serviceCenters: [
          {
            name: 'Osogbo',
            address:
              'KM3, Gbongan/Ibadan Road, NIPCO Petrol Station, Ogo Oluwa, Osogbo.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'Ile-Ife',
            address:
              'EXODUS Filling Station, opposite Airtel Office, Mayfair, Ile-lfe, Osun State',
            email: ['info@giglogistics.com'],
            phone: [],
          },
        ],
      },
      {
        name: 'Sokoto',
        serviceCenters: [
          {
            name: 'Sokoto',
            address: '3/4 Maiduguri Road Gawon Nama Area',
            email: ['info@giglogistics.com'],
            phone: [],
          },
        ],
      },
      {
        name: 'Adamawa',
        serviceCenters: [
          {
            name: 'Yola',
            address:
              'Plot 2 Bekaji Plaza, Bekaji Karewa Road, By Fire Service Roundabout, Jimeta Yola.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
        ],
      },
      {
        name: 'Zamfara',
        serviceCenters: [
          {
            name: 'Zamfara',
            address: 'C 1, A A Master Plaza Canteen Road Gusau.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
        ],
      },
      {
        name: 'Damaturu',
        serviceCenters: [
          {
            name: 'Damaturu',
            address: 'Shop 2, Adhaza Plaza, Gashuwa Road, Damaturu.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
        ],
      },
      {
        name: 'Rivers',
        serviceCenters: [
          {
            name: 'PHC Alakahia',
            address:
              'Linus Book Shop Building beside Today FM Road, East-West Road PHC.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'PHC Woji',
            address:
              'No 118 Off Woji GRA, Same Building with Miskay Boutique By Bodo Junction, Port Harcourt.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'PHC Stadium',
            address: 'No 9 Stadium Road, Beside Benjack, Port Harcourt',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'PHC Artillery',
            address:
              'Cocaine Village Junction, Off Aba Rd, opposite Genesis, Rumuogba, Port Harcourt.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'PHC Peter Odili',
            address: 'No 89 Peter Odili Road Port Harcourt.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'PHC Ada George',
            address: 'No 18 Ada George By Okilton Junction, Port Harcourt.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'PHC Tombia',
            address: '67 Tombia Ext GRA, Port Harcourt.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'PHC Olu Obasanjo',
            address: '61, Olu Obasanjo Road, Port Harcourt.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
          {
            name: 'PORTHARCOURT ELELENWON',
            address:
              'No 299 Old Refinery Road, by De-Young Junction, Elelenwo, Port Harcourt.',
            email: ['info@giglogistics.com'],
            phone: [],
          },
        ],
      },
    ],
  },
  {
    country: 'Ghana',
    aliases: ['gh', 'ghana'],
    headline: {
      name: '',
      address: '',
      email: ['giglghana@giglogistics.ng'],
      phone: [
        {
          name: 'Ghana Line',
          phonenumber: ['+233 057 534 1111', '+233 055 227 7787'],
        },
      ],
    },
    states: [
      {
        name: 'Greater Accra',
        serviceCenters: [
          {
            name: 'Accra',
            address: '3 la Bawalehi road okponglo, beside Ecobank legon Accra',
            email: ['giglghana@giglogistics.ng'],
            phone: [{ name: 'Ghana line', phonenumber: ['+233 057 534 1111'] }],
          },
          {
            name: 'Accra-2',
            address:
              '126 Spintex Road, By Riklyn Hotel, basket junction, Accra.',
            email: ['giglghana@giglogistics.ng'],
            phone: [],
          },
        ],
      },
      {
        name: 'Nothern Region',
        serviceCenters: [
          {
            name: 'Tamale',
            address:
              'M259, Hurlingham street, West Hospital Road Second gate along Tamale submetro.',
            email: ['giglghana@giglogistics.ng'],
            phone: [],
          },
        ],
      },
      {
        name: 'Western Region',
        serviceCenters: [
          {
            name: 'Takoradi',
            address: 'Plot 65/6 J.H Kobinnah Street, Takoradi.',
            email: ['giglghana@giglogistics.ng'],
            phone: [],
          },
        ],
      },
      {
        name: 'Ashanti Region',
        serviceCenters: [
          {
            name: 'Kumasi',
            address:
              'Solid FM buidling, Central Market Roman Hill Down, Kumasi.',
            email: ['giglghana@giglogistics.ng'],
            phone: [],
          },
        ],
      },
      {
        name: 'Central Region',
        serviceCenters: [
          {
            name: 'Kasoa',
            address:
              'Cx-030-9048, Kasoa new market road, opposite Soccabet, Kasoa.',
            email: ['giglghana@giglogistics.ng'],
            phone: [],
          },
        ],
      },
    ],
  },
  {
    country: 'United Kingdom',
    aliases: ['uk', 'united-kingdom'],
    headline: {
      name: '',
      address: '',
      email: ['gigluk@giglogistics.com'],
      phone: [],
    },
    states: [
      {
        name: 'England',
        serviceCenters: [
          {
            name: 'London',
            address:
              'GIG Logistics UK, 381-383 Lewisham High St, London SE13 6NZ, United Kingdom',
            // 'C/O GIG LOGISTICS SAFESTORE,  Crayford Unit V Acorn Industrial Park, Dartford, London, DA1 4AL',
            // 'C/O GIG LOGISTICS LTD, World Express Center, GalleyMead Road, Colnbrook ,Slough ,SL30EN',
            email: ['gigluk@giglogistics.com'],
            phone: [],
          },
        ],
      },
    ],
  },
  {
    country: 'United States',
    aliases: ['uk', 'usa', 'united-states'],
    headline: {
      name: '',
      address: '',
      email: ['giglusa@giglogistics.com'],
      phone: [
        {
          name: 'USA line',
          phonenumber: ['+1 281-741-1784'],
        },
        // {
        //   name: 'Additional lines',
        //   phonenumber: [
        //     '+1 281-817-1085',
        //     '+1 281-688-4066',
        //     '+1 281-688-4074',
        //     '+1 281-688-4075',
        //   ],
        // },
        // {
        //   name: 'Whatsapp line',
        //   phonenumber: ['+1 832-998-9925'],
        // },
        // {
        //   name: 'Toll free line',
        //   phonenumber: ['+1 866-307-0403'],
        // },
      ],
    },
    states: [
      {
        name: 'Texas',
        serviceCenters: [
          {
            name: 'Houston',
            address: 'C/O GIGL 14081 Westheimer Road, Houston TX. 77077',
            email: ['giglusa@giglogistics.com'],
            phone: [
              {
                name: 'Main',
                phonenumber: ['+1 281 741 1784', '281-817-1085'],
              },
              {
                name: 'Whatsapp',
                phonenumber: ['+1-832-998-9925'],
              },
              {
                name: 'Toll free',
                phonenumber: ['+1-866-307-0403'],
              },
              {
                name: 'Other lines',
                phonenumber: [
                  '+1 - 281 - 688 - 4066',
                  '+1 - 281 - 688 - 4074',
                  '+1 - 281 - 688 - 4075',
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    country: 'China',
    aliases: ['china', 'china'],
    headline: {
      name: '',
      address: '',
      email: ['china@giglogistics.com'],
      phone: [],
    },
    states: [
      {
        name: 'China',
        serviceCenters: [
          {
            name: 'GIG Logistics Warehouse China',
            address:
              // 'AB-116,Area A, Tongtong Commercial City, No. 101, Guangyuan West Road, Yuexiu District, Guangzhou. ',
              // 'C/O GIG LOGISTICS SAFESTORE,  Crayford Unit V Acorn Industrial Park, Dartford, London, DA1 4AL',
              // 'C/O GIG LOGISTICS LTD, World Express Center, GalleyMead Road, Colnbrook ,Slough ,SL30EN',
              // ' 广州市越秀区广园西路101号通通商贸城A区AB一132档 (AB-132,Area A, Tongtong Commercial City, No. 101, Guangyuan West Road, Yuexiu District, Guangzhou)',
              '3308, Building 3, Shanxi Building, sanyuanli Avenue, yuexiu District, Guangzhou',

            email: ['china@giglogistics.com'],
            phone: [],
          },
          {
            name: 'GIG Logistics Warehouse China',
            address:
              'D115 Consolidation Center, 1st Floor, Building D, Guangdabodu, No. 300 Qingcha Road, Shijing Street, Baiyun District, Guangzhou 广州市白云区石井街道庆槎路300号广大播都D栋一楼D115集运中心',
            email: ['china@giglogistics.com'],
            phone: [],
          },
        ],
      },
    ],
  },
];
